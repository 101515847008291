<template>
  <div class="registerPageWrapper">
    <el-row class="contentRow">
      <el-col :span="12" class="imgCol">
        <img class="loginImg" src="@/assets/img/register.png">
      </el-col>
      <el-col :span="12" class="loginCol">
        <!-- 登录信息 -->
        <el-row class="loginInfo">
          <el-form ref="loginRef" :model="loginObj" >
            <el-form-item label="" class="phoneItem">
              <el-input v-model.trim="loginObj.phoneNum" placeholder="请输入手机号">
                <template slot="prepend">+86</template>
              </el-input>
            </el-form-item>
            <el-form-item label="" class="codeItem">
              <el-input v-model.trim="loginObj.verifyCode" placeholder="请输入手机验证码"></el-input>
              <a>发送验证码</a>
            </el-form-item>
            <el-form-item label="">
              <el-input type="password" v-model.trim="loginObj.password" autocomplete="off" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input type="password" v-model.trim="loginObj.passwordCheck" autocomplete="off" placeholder="请再次确认密码"></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <!-- 登录操作 -->
        <el-row class="operateRow">
          <el-button type="primary" size="big">确定注册</el-button>
        </el-row>
        
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name:'registerPage',
  data(){
    return {
      loginObj:{
        phoneNum:'',
        verifyCode:'',
        password:'',
        passwordCheck:''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .registerPageWrapper{
    padding: 0 100px;

    .contentRow{
      display: flex;
      height: 400px;
      
      .imgCol{
        text-align: right;
      }

      .loginCol{
        height: 100%;
        padding: 60px 200px;
        border: 1px solid #ccc;
        border-left: none;

        .loginInfo{

          .el-form-item.codeItem{
            a{
              position: absolute;
              right: 20px;
              color: deepskyblue;
              cursor: pointer;
            }
          }
          
        }

        .operateRow{
          margin-top: 15px;

          .el-button{
            padding-left: 40px;
            padding-right: 40px;
            background-color: #1b91d7;
            border-color: #1b91d7;
          }
          
        }
        
      }

    }
    
  }
</style>
<style lang="scss">
  .registerPageWrapper{

    .contentRow{

      .loginCol{

        .el-form-item,.phoneItem{

          .el-input-group__prepend{
            padding-left: 10px;
            padding-right: 25px;
            color: #333;

            &::after{
              position: absolute;
              right: 4px;
              content: "\e6df";
              font-family: element-icons!important;
              color: #333;

            }
            
          }
          
        }

        .el-form-item.codeItem{

          .el-input__inner{
            padding-right: 100px;
          }
          
        }
        
      }
      
    }
    
  }
</style>